import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";
import { getToken } from "../../auth/AuthToken";
import { toast } from "react-toastify";

// Initial state
const initialState = {
  companies: [],
  currentCompany: null,
  isLoading: false,
};

// Async thunk for creating a new company
export const createCompany = createAsyncThunk(
  "companies/createCompany",
  async ({ company, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/companies?account_id=${selectedAccount}`;

    try {
      const response = await axios.post(url, company, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for updating an existing company
export const editCompany = createAsyncThunk(
  "companies/editCompany",
  async ({ company, companyId, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/companies/${companyId}?account_id=${selectedAccount}`;

    try {
      const response = await axios.put(url, company, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCompany = createAsyncThunk(
  "companies/deleteCompany",
  async ({ companyId, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/companies/${companyId}?account_id=${selectedAccount}`;

    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      toast.success("Company deleted successfully");
      return { companyId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompany = createAsyncThunk(
  "companies/getCompany",
  async ({ companyId, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = apiBaseUrl + `/companies/${companyId}?account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for getting all companies
export const getAllCompanies = createAsyncThunk(
  "companies/getAll",
  async ({ searchValue }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = apiBaseUrl + `/companies?q=${searchValue}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Company slice
export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllCompanies.rejected, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createCompany.pending, (state) => { })
      .addCase(createCompany.fulfilled, (state, action) => {
        state.companies.push(action.payload);
        state.isLoading = false;
      })
      .addCase(createCompany.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteCompany.fulfilled, (state, action) => {
        state.companies = state.companies.filter(
          (company) => company.id !== action.payload.companyId
        );
      })
      .addCase(getCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.currentCompany = action.payload;
        state.isLoading = false;
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.isLoading = true;
      });
  },
});

export default companySlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";
import { getToken } from "../../auth/AuthToken";
import { toast } from "react-toastify";
import { selectAccount } from "./user";

const initialState = {
  formTemplatesList: [],
  formTemplates: [],
  currentFormTemplate: null,
  isLoading: false,
  totalFormTemplatesCount: 0,
};

export const createFormTemplate = createAsyncThunk(
  "visit_templates/createFormTemplate",
  async ({ formTemplate, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/visit_templates?account_id=${selectedAccount}`;

    try {
      const response = await axios.post(
        url,
        { visit_template: formTemplate },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editFormTemplate = createAsyncThunk(
  "visit_templates/editFormTemplate",
  async ({ formTemplate, formTemplateId, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/visit_templates/${formTemplateId}?account_id=${selectedAccount}`;

    try {
      const response = await axios.put(
        url,
        { visit_template: formTemplate },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateReportDetails = createAsyncThunk(
  "visit_templates/update_report_details",
  async ({ formTemplate, formTemplateId, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/visit_templates/${formTemplateId}/update_report_details?account_id=${selectedAccount}`;

    try {
      const response = await axios.patch(
        url,
        { visit_template: formTemplate },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const deleteFormTemplate = createAsyncThunk(
  "visit_templates/deleteFormTemplate",
  async ({ formTemplateId, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/visit_templates/${formTemplateId}?account_id=${selectedAccount}`;

    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });

      toast.success("Form template deleted successfully");
      return { formTemplateId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllFormTemplates = createAsyncThunk(
  "visit_templates/getAllFormTemplates",
  async (
    { searchQuery, searchTags, page, rowsPerPage, selectedAccount, archive = false },
    { rejectWithValue }
  ) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/visit_templates?q=${searchQuery}&tags=${searchTags}&page=${page + 1
      }&per_page=${rowsPerPage}&account_id=${selectedAccount}&archive=${archive}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      console.log(response.data, 'response.data')
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFormTemplate = createAsyncThunk(
  "visit_templates/getFormTemplate",
  async ({ formTemplateId, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/visit_templates/${formTemplateId}?account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const copyFormTemplate = createAsyncThunk(
  "visit_templates/copyFormTemplate",
  async ({ id, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/visit_templates/${id}/copy_visit_template?account_id=${selectedAccount}`;

    try {
      const response = await axios.post(url, { id }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFormTemplates = createAsyncThunk(
  "visit_templates/getFormTemplates",
  async (selectedAccount,
    { rejectWithValue }
  ) => {

    const bearerToken = getToken();
    const url = `${apiBaseUrl}/visit_templates/unpaginated_users_templates?account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      console.log(response.data, 'data')
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAssociatedTestsOrder = createAsyncThunk(
  "visit_templates/updateAssociatedTestsOrder",
  async ({ formTemplateId, order, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/visit_templates/${formTemplateId}/update_order?account_id=${selectedAccount}`;

    try {
      const response = await axios.patch(
        url,
        { visit_template: { order_test_ids: order } },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const formTemplateSlice = createSlice({
  name: "formTemplate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllFormTemplates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormTemplates.fulfilled, (state, action) => {
        state.formTemplatesList = action.payload.visit_templates;
        state.isLoading = false;
        state.totalFormTemplatesCount = action.payload.meta.total_count;
      })
      .addCase(getAllFormTemplates.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getFormTemplates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFormTemplates.fulfilled, (state, action) => {
        state.formTemplates = action.payload.visit_templates;
        state.isLoading = false;
      })
      .addCase(getFormTemplates.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getFormTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFormTemplate.fulfilled, (state, action) => {
        state.currentFormTemplate = action.payload;
        state.isLoading = false;
      })
      .addCase(getFormTemplate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateReportDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReportDetails.fulfilled, (state, action) => {
        state.currentFormTemplate = action.payload;
        state.isLoading = false;
      })
      .addCase(updateReportDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(editFormTemplate.pending, (state) => { })
      .addCase(editFormTemplate.fulfilled, (state, action) => {
        state.currentFormTemplate = action.payload;
        state.isLoading = false;
      })
      .addCase(editFormTemplate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteFormTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFormTemplate.fulfilled, (state, action) => {
        state.formTemplatesList = state.formTemplatesList.filter(
          (template) => template.id !== action.payload.formTemplateId
        );
        state.isLoading = false;
      })
      .addCase(deleteFormTemplate.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateAssociatedTestsOrder.fulfilled, (state, action) => {
        state.currentFormTemplate = action.payload;
        toast.success("Order updated successfully");
      })
      .addCase(updateAssociatedTestsOrder.rejected, (state, action) => {
        toast.error(`Failed to update order: ${action.payload}`);
      })
      .addCase(copyFormTemplate.fulfilled, (state, action) => {
        toast.success("Form Template copied successfully");
      })
      .addCase(copyFormTemplate.rejected, (state, action) => {
        toast.error(`Failed to copy: ${action.payload}`);
      });
  },
});

export default formTemplateSlice.reducer;

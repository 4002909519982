import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import Interceptors from "./auth/interceptor";
import GradientCircularProgress from "../src/elements/GradientCircularProgress";

// Lazy-load the App component
const App = lazy(() => import('./App'));

Interceptors.setupInterceptors(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Suspense fallback={<GradientCircularProgress open={true} />}>
      <App />
    </Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

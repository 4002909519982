import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";
import { getToken } from "../../auth/AuthToken";
import { toast } from "react-toastify";

const initialState = {
  user: JSON.parse(localStorage.getItem("user")),
  selectedAccount: JSON.parse(localStorage.getItem("selectedAccount")),
  currentUser: null,
  roleWiseUser: null,
  userStatus: null,
  usersList: [],
  pendingUsersList: [],
  totalPendingUsersCount: 0,
  isLoading: false,
  totalUsersCount: 0,
};

export const loginUser = createAsyncThunk(
  "user/login",
  async (session, { rejectWithValue }) => {
    const url = apiBaseUrl + "/auth/login";
    try {
      const response = await axios.post(url, session, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createNewUser = createAsyncThunk(
  "user/createNewUser",
  async ({ user, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/users?account_id=${selectedAccount}`;

    try {
      const response = await axios.post(url, user, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "user/editUser",
  async ({ user, user_id, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/users/${user_id}?account_id=${selectedAccount}`;

    try {
      const response = await axios.put(url, user, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const inviteNewUsers = createAsyncThunk(
  "user/inviteNewUsers",
  async ({ id, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = apiBaseUrl + `/users/${id}/send_invite?account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const cancelInvite = createAsyncThunk(
  "user/cancelInvite",
  async ({ id, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = apiBaseUrl + `/users/${id}/remove_invite?account_id=${selectedAccount}`;
    try {
      const response = await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const acceptInvite = createAsyncThunk(
  "user/acceptInvite",
  async ({ id, selectedAccount }, { rejectWithValue }) => {
    const url = apiBaseUrl + `/users/${id}/accept_invite?account_id=${selectedAccount}`;
    try {
      const response = await axios.patch(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async ({ token, password, account_id }, { rejectWithValue }) => {
    const url = apiBaseUrl + "/users/update_password";
    try {
      const response = await axios.patch(
        url,
        { token, password, account_id },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUser = createAsyncThunk(
  "users/getUser",
  async ({ user_id, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/users/${user_id}?account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserStatus = createAsyncThunk(
  "users/getUserStatus",
  async ({ email }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/users/user_exists?email=${email}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRoleWiseUser = createAsyncThunk(
  "users/getRoleWiseUser",
  async (selectedAccount, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/users/role_wise_users?account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllUsers = createAsyncThunk(
  "user/getAll",
  async ({ searchValue, page, rowsPerPage, role, selectedAccount, archive = false }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url =
      apiBaseUrl +
      `/users?q=${searchValue}&page=${page + 1}&per_page=${rowsPerPage}&role=${role}&account_id=${selectedAccount}&archive=${archive}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPendingUsers = createAsyncThunk(
  "users/pendingUsers",
  async ({ searchValue, page, rowsPerPage, role, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = apiBaseUrl + `/users/pending_users?q${searchValue}&page=${page + 1}&per_page=${rowsPerPage}&role=${role}&account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
)

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async ({ userId, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/users/${userId}?account_id=${selectedAccount}`;

    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });

      return { userId };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.selectedAccount = null;
      localStorage.removeItem("user");
      localStorage.removeItem("selectedAccount");
    },
    selectAccount: (state, action) => {
      state.selectedAccount = action.payload;
      localStorage.setItem("selectedAccount", JSON.stringify(action.payload));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload;
        localStorage.setItem("user", JSON.stringify(state.user));
      })
      .addCase(getUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.currentUser = action.payload;
        state.isLoading = false;
      })
      .addCase(getUserStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserStatus.fulfilled, (state, action) => {
        state.userStatus = action.payload;
        state.isLoading = false;
      })
      .addCase(getRoleWiseUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getRoleWiseUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoleWiseUser.fulfilled, (state, action) => {
        state.roleWiseUser = action.payload;
        state.isLoading = false;
      })
      .addCase(getUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.usersList = action.payload.users;
        state.isLoading = false;
        state.totalUsersCount = action.payload.meta.total_count;
      })
      .addCase(getPendingUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPendingUsers.fulfilled, (state, action) => {
        state.pendingUsersList = action.payload.users;
        state.isLoading = false;
        state.totalPendingUsersCount = action.payload.meta.total_count;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.usersList = state.usersList.filter(
          (user) => user.id !== action.payload.userId
        );
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        toast.success("Password reset successfully");
      })
      .addCase(updatePassword.rejected, (state, action) => {
        toast.error(action.payload || "Failed to set password");
      })
  },
});

export const { logout, selectAccount } = userSlice.actions;
export default userSlice.reducer;

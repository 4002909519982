import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { apiBaseUrl } from "../../constants/urls";
import { getToken } from "../../auth/AuthToken";
import { toast } from "react-toastify";

const initialState = {
  examsList: [],
  currentExam: null,
  examDetails: null,
  isLoading: false,
  selectedTab: 1
};

export const createNewExam = createAsyncThunk(
  "exams/createNewExam",
  async ({exam, selectedAccount}, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/exams?account_id=${selectedAccount}`;
    try {
      const response = await axios.post(url, exam, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editExam = createAsyncThunk(
  "exams/editExam",
  async ({ exam, exam_id, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/exams/${exam_id}?account_id=${selectedAccount}`;

    try {
      const response = await axios.put(url, exam, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteExam = createAsyncThunk(
  "exams/deleteExam",
  async ({ exam_id , selectedAccount}, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/exams/${exam_id}?account_id=${selectedAccount}`;

    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      toast.success("Exam deleted successfully");
      return { exam_id };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getExam = createAsyncThunk(
  "exams/getExam",
  async ({ exam_id, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/exams/${exam_id}?account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getExamDetails = createAsyncThunk(
  "exams/getExamDetails",
  async ({ exam_id, selectedAccount}, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/exams/${exam_id}/public_exam?account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fillExam = createAsyncThunk(
  "exams/fillExam",
  async ({ exam_id, formData, selectedAccount }, { rejectWithValue }) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/exams/${exam_id}/fill_exam?account_id=${selectedAccount}`;

    try {
      const response = await axios.patch(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Required for sending FormData
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data; // Returns the API response data if successful
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error submitting the form");
    }
  }
);

export const getAllExams = createAsyncThunk(
  "exams/getAllExams",
  async (
    { searchQuery, page, rowsPerPage, selectedAccount },
    { rejectWithValue }
  ) => {
    const bearerToken = getToken();
    const url = `${apiBaseUrl}/exams?q=${searchQuery}&page=${page + 1
      }&per_page=${rowsPerPage}&account_id=${selectedAccount}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const examSlice = createSlice({
  name: "exam",
  initialState,
  reducers: {
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllExams.pending, (state) => {
        state.isLoading = true;
        state.currentExam = null;
      })
      .addCase(getAllExams.fulfilled, (state, action) => {
        state.examsList = action.payload.exams;
        state.isLoading = false;
        state.totalExamsCount = action.payload.meta.total_count;
      })
      .addCase(getAllExams.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(deleteExam.fulfilled, (state, action) => {
        state.examsList = state.examsList.filter(
          (exam) => exam.id !== action.payload.exam_id
        );
      })
      .addCase(getExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExam.fulfilled, (state, action) => {
        state.currentExam = action.payload;
        state.isLoading = false;
      })
      .addCase(getExam.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getExamDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExamDetails.fulfilled, (state, action) => {
        state.examDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(getExamDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fillExam.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fillExam.fulfilled, (state, action) => {
        state.examDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(fillExam.rejected, (state) => {
        state.isLoading = false;
      })
  },
});
export const { setSelectedTab } = examSlice.actions;

export default examSlice.reducer;

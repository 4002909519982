import React, { Suspense, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import Interceptors from "./auth/interceptor";
import GradientCircularProgress from "../src/elements/GradientCircularProgress";
import componentLoader from "./componentLoader"; // Import the loader

Interceptors.setupInterceptors(store);

function AppLoader() {
  const [Component, setComponent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    componentLoader(() => import("./App"), 2)
      .then((loadedModule) => setComponent(() => loadedModule.default))
      .catch((err) => setError(err));
  }, []);

  if (error) {
    return <div>Error loading application. Please try refreshing the page.</div>;
  }

  if (!Component) {
    return <GradientCircularProgress open={true} />;
  }

  return <Component />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AppLoader />
  </Provider>
);

// Optionally remove or modify based on analytics needs
reportWebVitals();
